#iconSelection {
    $local-background: $input-background-color;
    $local-border-color: $input-border-color;
    $local-svg-preview-size: 100px;
    $local-svg-preview-padding: 10px;
    $local-content-padding: 20px;
    $local-textarea-height: 500px;

    margin-bottom: 10px;
    max-width: 760px;

    .iconSelector-tabs {
        @extend %tabs;
        //display: flex;
        //align-content: flex-start;
        //margin-bottom: -1px;
        //
        //li {
        //    display: block;
        //    padding: 10px 15px;
        //    background: $local-background;
        //    border: 1px solid $local-border-color;
        //    margin-right: -1px;
        //    margin-bottom: 0;
        //    cursor: pointer;
        //
        //    &.active {
        //        border-bottom-color: $local-background;
        //        font-weight: bold;
        //    }
        //}
    }

    .iconSelector-content {
        @extend %tabs-content;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        //background: $local-background;
        //padding: $local-content-padding;
        //border-radius: 0 4px 4px 4px;
        //border: 1px solid $local-border-color;
    }

    .icon-preview-holder {
        text-align: center;
        max-width: 300px;
    }

    .icon-preview {
        display: inline-block;
        border: 2px solid $submit-button-color;
        padding: $local-svg-preview-padding;

        svg {
            display: block;
            width: $local-svg-preview-size;
            height: $local-svg-preview-size;
        }
    }

    .iconSelector-input {
        width: calc(100% - #{$local-svg-preview-size - ($local-svg-preview-padding * 2)});
        padding-left: $local-content-padding;

        #searchField {
            margin: 0 0 10px 0;
            display: block;
            width: 100%;
        }

        textarea,
        .iconSelector-predefinedListHolder {
            width: 100%;
            height: $local-textarea-height;
        }

        .iconSelector-predefinedListHolder {
            overflow: auto;
            border: 1px solid $local-border-color;
        }

        .iconSelector-predefinedList {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            padding: 10px 5px;
            margin: 0;

            li {
                width: 16.666666%;
                padding: 2px 5px;
                cursor: pointer;

                &.active {
                    .iconSelector-predefinedList-itemInner {
                        background: rgba($submit-button-color, 1);
                        font-weight: bold;
                        color: $input-background-color;

                        svg {
                            fill: currentColor;
                        }
                    }
                }
            }
        }

        .iconSelector-predefinedList-itemInner {
            background: rgba($admin-background-color, .85);
            padding: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;

            > svg {
                display: block;
                flex-grow: 1;
            }

            > span {
                display: block;
                text-align: center;
                font-size: 10px;
                margin-top: 5px;
            }
        }
    }
}