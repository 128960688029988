@mixin vertical-center() {
    transform:  translateY(-50%);
    top: 50%;
}

@mixin horizontal-center() {
    transform:  translateX(-50%);
    left: 50%;
}

@mixin all-center() {
    transform:  translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

@mixin logo-size($width) {
    width: $width;
    height: getLogoHeight($width);
}

@mixin wrap-links() {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

// Sizing shortcuts
@mixin size($width, $height, $prefix: '') {
    #{$prefix}width: $width;
    #{$prefix}height: $height;
}

@mixin square($size, $prefix: '') {
    @include size($size, $size, $prefix);
}

//
//  Media Query
// ---------------------------------------
@mixin media-query($size, $min: min) {
    @if $size == xs {
        @content;
    } @else if $size == sm {
        @if $min == min {
            @media (min-width: $screen-sm-min) { @content; }
        } @else {
            @media (max-width: $screen-sm-min - 1) { @content; }
        }
    } @else if $size == md {
        @if $min == min {
            @media (min-width: $screen-md-min) { @content; }
        } @else {
            @media (max-width: $screen-md-min - 1) { @content; }
        }
    } @else if $size == lg {
        @if $min == min {
            @media (min-width: $screen-lg-min) { @content; }
        } @else {
            @media (max-width: $screen-lg-min - 1) { @content; }
        }
    }
}


