// Imported inside of _app-modal.scss
// Scoped within .inventModal

&.customItemModal {
    .customItemModal-nameQty {
        .text-orange {
            @extend %list-item-title;
            padding-left: 10px;
        }

        .addButton {
            padding: 5px 0 5px 10px;
        }
    }

    fieldset {
        display: block;
        border: 1px solid rgba($fieldset-border-color, .3);
        margin-bottom: 15px;
        //padding: 15px;

        legend {
            @extend %list-item-title;
            color: $orange;
            font-size: inherit;
            border: none;
            padding: 0 5px;
            display: inline-block;
            width: auto;
            margin-left: -5px;
        }
    }

    .form-control {
        padding: 10px;
    }

    textarea {
        min-height: 130px;
    }

    .customItemModal-valueField {
        width: calc(50% - 8px);
    }

    .customItemModal-dimensionFields {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 15px 0;

        > .form-group {
            flex-basis: 50%;

            &:nth-child(1),
            &:nth-child(3) {
                padding-right: 8px;
            }

            &:nth-child(2),
            &:nth-child(4) {
                padding-left: 8px;
            }
        }

        .btn-group {
            .btn {
                padding: 13px;
                font-size: 16px;
                line-height: 1;

                &.btn-default {
                    color: $black;
                }

                &:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }

    .form-control-with-prefix {
        display: flex;
        align-items: center;
    }

    .form-control-prefix {
        @extend %list-item-title;
        color: $orange;
        padding-right: 5px;
    }

    .customItemModal-footerButtons {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .btn {
            display: block;
            margin-left: 0;

            &.btn-danger {
                order: 2;
                margin-top: 20px;
            }
        }

        @include media-query(sm) {
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .btn {
                flex-grow: 0;
                margin-left: 10px;

                &.btn-danger {
                    order: 0;
                    margin: 0 10px 0 0;
                }
            }
        }
    }
}