// NOTE: Relies on form and bootstrap styling from Allied Pickfords theme

// ============================================================================ //
//  Import config first
// ============================================================================ //
@import "config/variables";
@import "utils/function";
@import "utils/mixins";
@import "utils/placeholders";
@import "utils/animations";

// ============================================================================ //
//  Components
// ============================================================================ //
@import 'lib/react-confirm-alert';

//
//  Invent Button
// ---------------------------------------
@import "components/btn-inventory";

//
//  Page template overrides for app
// ---------------------------------------
// Hide Allied footer and content for inventory page
.site.quote-form--inventory {
    .site-content,
    .site-footer {
        display: none;
    }
}

// Make orange nav fixed and pad out .site-header
.nav-orange {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    max-height: 105px;
    transition: max-height $transition-length, top $transition-length;

    @each $size, $top in $orange-bar-top {
        @include media-query($size) {
            top: $top;
        }
    }

    .sticky & {
        max-height: 0;
        overflow: hidden;

        @each $size, $top in $scrolled-header-height {
            @include media-query($size) {
                top: $top;
            }
        }
    }
}

.site-header {
    @each $size, $padding in (md: map-get($header-height, md), lg: map-get($header-height, lg)) {
        @include media-query($size) {
            padding-top: $padding;
        }
    }
}

//
//  Invent App
// ---------------------------------------
#inventApp .inventApp-inner,
#inventApp .inventApp-wrapper,
#inventApp .inventApp-transition,
#inventApp .appScreen,
#inventApp .appScreen > .padded-container {
    @each $size, $height in $header-height {
        @include media-query($size) {
            min-height: calc(100vh - #{$height});
        }
    }
}

#inventApp {
    flex-grow: 1;
    overflow: auto;
    position: relative;

    // Scope it all inside the Invent App to avoid unwanted overrides
    @import "components/app";
    @import "components/progress-bar"; // Relies on Bootstrap styling from theme
    @import "components/cover-loader";
    @import "components/grid-list";
    @import "components/item-list";
    @import "components/app-screen";
    @import "components/screen-transitions";
    @import "components/app-alert";
    @import "components/summary";
}

#inventApp,
.inventApp-modal {
    // Scope sharing inside the Invent App or Invent Modal to avoid unwanted overrides
    @import "components/utility-classes";
    @import "components/add-button";
    @import "components/checkbox";
    @import "components/forms";
}


// Modal needs to live outside of app scope because the HTML gets insert at the end of the body
@import "components/app-modal"; // Relies on Bootstrap styling from theme
