.invent-status {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 54px;
    flex-shrink: 0;

    > .dashicons-before {
        display: block;

        &:before {
            display: block;
            margin: 0 auto;
        }
    }

    > .invent-status-label {
        display: block;
        line-height: 1;
        font-size: 10px;
        margin-top: -1px;
    }

    &--active {
        color: $success-color;
    }

    &--inactive {
        color: $error-color;
    }

    &--ready-to-sync {
        color: $warning-color;
    }

    &--sync-error {
        color: $error-color;
    }

    &--synced {
        color: $success-color;
    }
}