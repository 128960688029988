%vertical-inline-block {
    display: inline-block;
    vertical-align: middle;
}

%vertiaclly-aligned-label {
    @extend %vertical-inline-block;
    margin-left: 10px;
}

%styless-button {
    background: transparent;
    border: none;
}

%list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 round(($grid-gutter-width * 1) / -2);

    @include media-query(sm) {
        margin: 0;
    }
}

%list-item {
    padding: round(($grid-gutter-width * .75) / 2);
    color: $white;

    @media (min-width: 640px){
        padding: round(($grid-gutter-width * 1) / 2);
    }
}

%list-item-inner {
    @include square(100%);
    position: relative;
    display: flex;
    padding: $grid-item-padding;
    background: $list-item-background;
    cursor: pointer;
    transition: background-color $transition-time;

    &:hover {
        background: $list-item-background--active;
    }
}

%list-item-title {
    font-weight: bold;
    margin-bottom: 0;
}

%list-item-icon {
    svg {
        @include square(100%);
        display: block;
        fill: $orange;
    }
}

%subtle-orange-hover {
    &:hover,
    &:active,
    &:focus {
        border-radius: 4px;
        background: rgba($orange, .25);
    }
}

%clickable-title {
    @extend %subtle-orange-hover;
    display: inline-block;
    cursor: pointer;
    padding: 5px 10px;
}