
// ============================================================================ //
//  Import config first
// ============================================================================ //
@import "config/variables.color";
@import "utils/placeholders";

// ============================================================================ //
//  Components
// ============================================================================ //
@import "components/wp-list-table";
@import "components/spinning-loader";
@import "components/icon-selection";
@import "components/forms";
@import "components/invent-status";
@import "components/admin-tabs";
@import "components/inventory-summary";

// ============================================================================ //
//  Styling
// ============================================================================ //
.wp-header-end {
    &.visible {
        visibility: visible;
        margin: 5px 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #fafafa;
    }
}

.button-link,
.button {
    &.dashicons-before {
        > span {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.text-green {
    color: $success-color;
}

.tablenav {
    .subsubsub {
        margin-top: 0;
    }
}

.tablenav-pages-navspan {
    height: auto;
}

.invent-pagination {
    margin-bottom: 5px;
}

.text-muted {
    color: $muted-text-color;
}

.code {
    overflow: auto;
    border: 1px solid $input-border-color;
    padding: 10px 20px;
    background: rgba($admin-background-color, .25);

    .code-wrap-cbx:checked ~ & {
        > pre {
            white-space: pre-wrap;       /* Since CSS 2.1 */
            white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
            white-space: -pre-wrap;      /* Opera 4-6 */
            white-space: -o-pre-wrap;    /* Opera 7 */
        }
    }
}