.inventApp-coverLoader {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    transition: height $transition-length, top $transition-length;
    background: rgba($white, .15);
    color: $orange;

    @each $size, $height in $header-height {
        @include media-query($size) {
            top: $height;
        }
    }

    @each $size, $height in $scrolled-header-height {
        @include media-query($size) {
            .site-header.sticky + .with-form--inventory & {
                top: $height;
            }
        }
    }

    &-spinner {
        @include horizontal-center();
        position: absolute;
        background: $white;
        border-radius: 50%;
        top: 20%;
        padding: 5px;
        box-shadow: 0 0 10px rgba($black, .25);
        animation: slide-down-loader .3s;
        width: 42px;
        height: 42px;
    }
}