.parsley-errors-list {
    margin-top: 5px;
    color: $error-color;
}

input.parsley-error {
    border-color: $error-color;

    &:focus {
        border-color: $error-color;
        box-shadow: 0 0 2px rgba($error-color,.8);
    }
}

.form-legend {
    background: rgba(black, .05);
    padding: 10px;
    border-radius: 4px;
    line-height: 1.8;

    &--spaced {
        > * {
            display: inline-block;
            margin: 5px 0;
        }
    }

    &--flex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > * {
            flex-basis: 20%;
            line-height: 1.2;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 5px;
            min-width: 200px;

            .form-legend-description {
                line-height: 1.2;
                display: block;
                margin-left: 10px;
                font-size: 12px;
            }
        }
    }
}

.setting-badge {
    padding: 3px 5px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    line-height: 1;
    white-space: nowrap;
    text-shadow: -1px -1px 0 rgba(black, .1);

    &--system {
        background: darken($error-color, 7%);
    }

    &--site {
        background: darken($warning-color, 3%);
    }
}

.label-sm {
    font-size: 11px;
}

#dimensionsInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    > div {
        > input {
            max-width: calc(100% - 30px);
            display: inline-block;
        }
    }

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-end;


        > div {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.divided-sections {
    h2 {
        font-size: 1.5em;
    }

    .form-table + h2 {
        &:before {
            content: "";
            display: block;
            margin: 0 0 30px;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #fafafa;
        }
    }
}