$transition-time: .3s;

//
//  Colors
// ---------------------------------------
$white:         #fff;
$black:         #000;
$gray-base:     #050708;
$gray:          #ccc;
$gray-darker:   #656565;

$orange:        #f58124;
$blue:          #407dca;
$yellow:        #fdd51c;
$red:           #e80025;
$green:         #5cb85c;

//
//  Components
// ---------------------------------------
$progress-bar-height:               30px;
$grid-item-padding:                 10px;
$border-radius-base:                2px;
$grid-gutter-width:                 25px;
$transition-length:                 300ms;
$list-item-background:              rgba($black, .3);
$list-item-background--active:      rgba($black, .5);
$fieldset-border-color:             $gray;

$app-screen-padding: (
        sm: round($grid-gutter-width * .75),
        md: round($grid-gutter-width * 1)
);

//
//  Header heights
// ---------------------------------------
$header-height: (
        xs: 50px,
        sm: 70px,
        md: 141px,
        lg: 202px
);

$scrolled-header-height: (
        sm: 55px,
        md: 65px
);

$orange-bar-top: (
        md: 75px,
        lg: 105px
);

//
//  Media queries breakpoints
// ---------------------------------------
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

// Small screen / tablet
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
$screen-md:                  1000px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
$screen-lg:                  1300px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);