/*!
 Theme Name:    alliedpickfords
 Author:        Optimising
 Author URI:    http://optimising.com.au/
 Description:   Theme for Allied Pickfords Australia
 Version:       1.0.0
 License:       GNU General Public License v2 or later
 License        URI: http://www.gnu.org/licenses/gpl-2.0.html
*/
// ============================================================================ //
//  Import own config first
// ============================================================================ //
@import "config/variables";
@import "utils/functions";
@import "utils/mixins";
@import "utils/logo-sprites";
@import "utils/placeholders";

$z-index--quote-form: 2;
$z-index--page-title: 1;

// ============================================================================ //
//  Import Bootstrap & Font Awesome
// ============================================================================ //
@import "lib/bootstrap";
@import "lib/font-awesome";

// ============================================================================ //
//  Slick JS Carousel
// ============================================================================ //
@import "lib/slick";

// ============================================================================ //
//  Theme Partials
// ============================================================================ //
@import "utils/utility-classes";
@import "partials/font";
@import "partials/layout";
@import "partials/nav-orange";
@import "partials/navbar-white";
@import "partials/quote-tab";
@import "partials/btns-n-fields";
@import "partials/carousels";
@import "partials/counter";
@import "partials/footer";
@import "partials/page-header-banner";
@import "partials/quote-form/quote-form";
@import "partials/icons";
@import "partials/title-navigation";
@import "partials/content-blocks";
@import "partials/parnter-logos";
@import "partials/date-picker";
@import "partials/timeline";
@import "partials/content-section";
@import "partials/contact-locations";
@import "partials/news-posts";
@import "partials/single-post-page";
@import "partials/lazyload";
@import "partials/jquery-autocomplete";
@import "partials/widget-side-menus";
@import "partials/widget-post-author";

// ============================================================================ //
//  Inventory App
// ============================================================================ //
@import "inventory-app/public/public.scss";
@import "inventory-app/admin/admin.scss";

//
//  Mobile sub menu toggle button
// ---------------------------------------
.mobile-dropdown-toggle-btn {
    display: block !important;
    width: 40px;
    position: absolute !important;
    right: 0;
    top: -1px;
    border: 0;
    background: rgba($black, .10) !important;

    &:before {
        @extend .fa;
        @extend .fa-angle-down;

        transition: transform .3s;
        font-size: 1.2em;
        margin-right: 0;
        position: relative;
        top: 1px;
        color: $text-color;

        .dropdown.open & {
            transform: rotate(-180deg);
        }
    }

    .caret {
        display: none;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
        display: none !important;
    }
}

// ============================================================================ //
//  Theme Styles (those that didn't warrant their own file)
// ============================================================================ //
.entry-title {
    margin-top: 0;
}

.entry-footer {
    padding: 10px 0;

    > span {
        margin-right: $padding-small-horizontal;
    }
}

.comments-link a {
    &:before {
        @extend .fa;
        @extend .fa-comment-o;
        margin-right: 6px;
    }
}

.post-edit-link {
    &:before {
        @extend .fa;
        @extend .fa-pencil;
        margin-right: 6px;
    }
}

//
//  For all map sections
// ---------------------------------------
#map {
    height: 300px;

    @media (min-height: 550px) {
        height: 480px;
    }
}

//
//  Forms
// ---------------------------------------
.in-page-form {
    .btn-holder {
        margin-top: 20px;
    }

    .form-control.has-error {
        @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
        color: $state-danger-text;
        border-color: $state-danger-text;

        &:focus {
            $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($state-danger-text, 20%);
            @include box-shadow($shadow);
            border-color: darken($state-danger-text, 10%);
        }
    }
}

//
//  Archive view (Resources section posts)
// ---------------------------------------
.archive {
    @extend %post-view;

    .moretag-holder {
        text-align: left;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

//
//  Hacky fix (soemthing to do with rounding or what not, the button wasn't lining up with the field)
// ---------------------------------------
.input-group-btn {
    .btn {
        padding-top: ($padding-base-vertical - 1);
        padding-bottom: ($padding-base-vertical - 1);
    }
}

//
//  Expand to read more
// ---------------------------------------
.btn-read-more {
    > i {
        transition: transform .3s;

        .collapse.in + p > &,
        .collapse.in + & {
            transform: rotate(-180deg);
        }
    }
}

.slick-slide { outline: none !important; }



//.slick-list {
//    height: auto !important;
//}
//.slick-slide {
//    float: none !important;
//}

