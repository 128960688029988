// Icon controller for SV and Font Awsome icons

.svg-australia-circle {
	
	.transparent-circle & {
		
		stroke: transparent;	
	}
}

.svg-car-body, .svg-pets-cat, .svg-cleaning-bottle, .svg-boxes-bottom {
	
	.reverse & {
		fill: $white;
	}
}

.svg-boxes-top {
	
	.reverse & {
		fill: transparent;
	}
}