@keyframes slide-down {
    0%      { transform: translateY(-100%); }
    100%    { transform: translateY(0%); }
}

@keyframes slide-up {
    0%      { transform: translateY(0); }
    100%    { transform: translateY(-100%); }
}

@keyframes slide-down-loader {
    from { top: -5%; }
}