.btn-inventory {
    display: none !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1 !important;
    font-size: 6px !important;
    position: relative;
    margin-left: 0 !important;
    min-width: 44px;

    .btn-inventory_icon {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 3.5em;
        margin: 0 .35em;

        > .fa {
            margin: .15em;
            font-size: 1.5em !important;
        }
    }

    .btn-inventory_text {
        display: block;
        padding-bottom: .5em;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transition: opacity .3s;
    }

    @media (min-width: $screen-sm-min) {
        font-size: 10px !important;

        .btn-inventory_text {
            padding-bottom: .5em;
        }
    }

    @media (min-width: $screen-lg-min) {
        font-size: 12px !important;

        .btn-inventory_text {
            padding-bottom: .75em;
        }
    }

    .sticky & {
        .btn-inventory_text {
            @media (min-width: $screen-sm-min) {
                opacity: 0;
            }
        }
    }

    .inventory-started & {
        &.hidden-mobile-nav {
            @media (min-width: $screen-sm-min) {
                display: flex !important;
            }
        }
        &.visible-mobile-nav {
            @media (max-width: $screen-sm-max) {
                display: flex !important;
            }
        }
    }
}