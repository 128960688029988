$local-remove-btn-size: 30px;
$local-remove-btn-padding: 5px;
$local-remove-btn-zIndex: 2;

.itemList {
    outline-width: 0;

    &-container {
        margin: 0 round(map-get($app-screen-padding, sm) / -2);

        @include media-query(sm) {
            margin: 0;
        }
    }

    &-item {
        @extend %list-item;
        flex-basis: 100%;

        &-inner {
            @extend %list-item-inner;
            flex-direction: row;
            align-items: center;
            padding: 0;
            min-height: 70px;
        }

        &-title {
            @extend %list-item-title;
            flex-grow: 1;
            text-align: left;
            padding-right: 5px;
            min-width: 110px;
            word-break: break-word;
            padding-top: 4px; // offset for the new font riding high
        }

        &-subText {
            display: block;
            font-size: 10px;
            color: $orange;
        }

        &-icon {
            @extend %list-item-icon;
            padding: 0 15px 0 0;

            svg {
                @include square(32px, 'max-');
                pointer-events: none;
            }
        }

        &-quantity {
            @extend %list-item-title;
            white-space: nowrap;
        }

        &-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .addButton {
                padding: 25px 15px 25px;
            }
        }

        &-loadingCover {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: transparent;
            z-index: 10;
        }

        &-spinner {
            color: $blue;

            &:first-child {
                font-size: 14px;
                padding: 15px;
            }
        }

        .checkbox {
            padding: 15px;
        }

        &.itemList-item--addCustom {
            .checkbox {
                opacity: 0;
            }
        }

        &.itemList-item--selected {
            .itemList-item-inner {
                padding-top: 0;
                padding-bottom: 0;
                background: $orange;
            }

            .itemList-item-icon {
                svg {
                    fill: $white;
                }
            }

            .itemList-item-subText {
                color: $white;
            }

            .itemList-item-actions {
                .addButton {
                    padding: 5px 15px 5px;

                    &:first-child {
                        padding-top: 10px;
                    }

                    &:last-child {
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}