.progress {
    height: $progress-bar-height;
    margin-bottom: 0;
}

.progress-bar {
    line-height: $progress-bar-height;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 2px; // To offset the new font riding higher
}