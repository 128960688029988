%tabs {
    display: flex;
    align-content: flex-start;
    margin-bottom: -1px;

    li {
        display: block;
        padding: 10px 15px;
        background: $tabs-background;
        border: 1px solid $tabs-border-color;
        margin-right: -1px;
        margin-bottom: 0;
        cursor: pointer;

        &.active {
            border-bottom-color: $tabs-background;
            font-weight: bold;
        }
    }
}

%tabs-content {
    background: $tabs-background;
    padding: $tabs-content-padding;
    border-radius: 0 4px 4px 4px;
    border: 1px solid $tabs-border-color;
}