// ============================================================================ //
//  Step 1 - choose Local, Interstate, International
// ============================================================================ //
//
//  Place holders
// ---------------------------------------
%_step1-selected-icon-circle {
    fill: $orange;
}
%_step1-selected-icon-foreground {
    fill: $white;
}

//
//  Styling
// ---------------------------------------
.step1 {
    .btn {
        width: 70%;
    }

    .phone {
        margin-top: 20px;
    }
}

.step1-options {
    margin-bottom: 20px;

    .icon-international2 {
        .svg-international2-circle {
            stroke: $orange;
        }
    }

    // Each li contains the radio option and label
    > li {
        input[type="radio"] {
            &:checked + label {
                // Local icon
                .svg-local-circle {
                    @extend %_step1-selected-icon-circle;
                }
                .svg-local-marker {
                    @extend %_step1-selected-icon-foreground;
                }

                // Interstate icon
                .svg-australia-circle {
                    @extend %_step1-selected-icon-circle;
                }
                .svg-australia-state {
                    @extend %_step1-selected-icon-foreground;
                }

                // International icon
                .svg-international2-circle {
                    @extend %_step1-selected-icon-circle;
                }
                .svg-international2-country {
                    @extend %_step1-selected-icon-foreground;
                }
            }
        }
    }
}