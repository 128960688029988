.summary {
    &-list {
        max-width: 500px;
        margin: 25px auto 40px;
    }

    &-action {
        margin: 40px 0;
    }

    &-footer {
        margin: 40px 0 25px;
    }

    &-disclaimer {
        @extend %list-item-title;
        color: $orange;
        font-size: 12px;
        font-style: italic;
    }
}

.summaryList {
    &-item {
        @extend %list-item-title;
        position: relative;
        margin-bottom: 40px;

        .fa-pencil {
            padding-left: .5em;
            color: $orange;
        }

        &-title {
            font-size: 20px;
            padding: 5px 0;
            margin-bottom: 20px;
            border-bottom: 1px solid $gray;

            > span {
                @extend %clickable-title;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0 -7px;
            cursor: pointer;
            transition: background-color $transition-length;

            &:hover,
            &:active,
            &:focus {
                border-radius: 4px;
                background: rgba($black, .15);
            }

            &-title {
                display: inline-block;
                text-align: left;
                padding: 6px 0 8px 10px;
            }

            .fa-pencil {
                padding-right: 10px;
                padding-left: 0;
            }

            &-commentIndicator {
                color: $gray-darker;
                padding-right: 15px;
            }
        }

        &-qty {
            display: inline-block;
            margin-left: .5em;
            color: $orange;
            flex-grow: 1;
            text-align: left;
            white-space: nowrap;
            padding-right: 15px;

            &:first-child {
                margin-left: 0;
                margin-right: .5em;
            }
        }
    }
}

.summaryEstimation {
    text-align: left;

    span {
        display: inline-block;
    }
    //@extend .summaryList-item;
    //
    &-item {
        margin: 10px 0;
        white-space: nowrap;
    }
}