$local-zIndex-bg-image: 1;
$local-zIndex-app: 2;

@each $size, $height in $header-height {
    @include media-query($size) {
        min-height: calc(100vh - #{$height});
    }
}

&.screen-transition {
    position: relative;
    overflow: hidden;

    .appScreen {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.inventApp-background {
    position: fixed;
    left: 0;
    right: 0;
    bottom: auto;
    background: no-repeat center center;
    background-size: cover;
    z-index: $local-zIndex-bg-image;
    transition: height $transition-length, top $transition-length;

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba($gray-base, .6);
    }

    @each $size, $height in $header-height {
        @include media-query($size) {
            top: $height;
            height: calc(100vh - #{$height});
        }
    }

    @each $size, $height in $scrolled-header-height {
        @include media-query($size) {
            .site-header.sticky + .with-form--inventory & {
                top: $height;
                height: calc(100vh - #{$height});
            }
        }
    }
}

.inventApp-wrapper {
    position: relative;
    z-index: $local-zIndex-app;
}