$local-increase-padding:        360px;
$local-header-button-padding:   10px;
$local-header-margin-bottom:    30px;
$header-button-size:            40px + $local-header-button-padding;

.appScreen {
    //position: absolute;
    //left: 0;
    //right: 0;

    > .padded-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }

    .dark-container {
        flex-grow: 1;
        padding: 0;
    }

    .container-fluid {
        padding: 0;

        @include media-query(sm) {
            padding: round($grid-gutter-width / 2);
        }
    }

    .dark-container {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        display: flex;
        flex-direction: column;
    }

    &-header {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        margin-bottom: $local-header-margin-bottom;

        @include media-query(md) {
            padding-top: 10px;
        }

        &-title {
            flex-grow: 1;
            font-weight: bold;
            padding-top: 15px;
            margin-bottom: 0;

            @media (min-width: $local-increase-padding) {
                padding-top: 16px;
            }

            > span {
                @extend %clickable-title;
                position: relative;
                padding-right: 40px;

                > .fa {
                    @include vertical-center();
                    position: absolute;
                    right: 10px;
                    margin-top: -5px; // To offset the new font riding higher, as this appear next to the title text
                }
            }
        }

        &-button {
            @extend %styless-button;
            @include square($header-button-size);
            flex: 0 0 ($header-button-size);
            color: $orange;
            padding: $local-header-button-padding 0 0 $local-header-button-padding;
        }
    }

    &-subTitle {
        font-weight: bold;
        margin-bottom: $grid-gutter-width;
    }

    &-main {
        flex-grow: 1;
        padding: 0 map-get($app-screen-padding, sm) round(map-get($app-screen-padding, sm) * 4);

        @media (min-width: $local-increase-padding) {
            padding: 0 map-get($app-screen-padding, md) round(map-get($app-screen-padding, md) * 3);
        }
    }

    &-footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;

        &-inner {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;
            align-items: stretch;
        }

        &-summaryText {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            padding: 0 10px;
            color: $orange;
            font-weight: bold;
            background: $black;

            > p {
                &:last-child {
                    margin-bottom: 0;
                }

                > span {
                    display: inline-block;
                    padding: 0 5px;
                }
            }
        }

        &-summaryAction {
            @extend %subtle-orange-hover;
            display: inline-block;
            cursor: pointer;
            transition: background-color .15s;
        }

        .btn {
            font-size: 18px;
            white-space: normal;
            width: 100px;
            padding-left: 20px;
            padding-right: 20px;

            @include media-query(sm) {
                font-size: 20px;
                width: 120px;
            }

            @include media-query(md) {
                width: 160px;
            }
        }

        @media (max-height: 599px) {
            &-summaryText {
                font-size: 14px;
            }

            .btn {
                font-size: 16px;
                padding-top: 10px;
                padding-bottom: 10px;
                min-height: 50px;
            }
        }
    }
}