.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1501;
    background: rgba($white, 0.7);
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.3s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.3s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.3s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.3s 0.2s forwards;
}

.react-confirm-alert {
    max-width: 500px;
    width: calc(100% - 30px);
}

.react-confirm-alert-body {
    //font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    //color: #666;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h2 {
    margin-top: 0;
    text-align: center;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin: 20px -5px 0;
    flex-wrap: wrap;
}

.react-confirm-alert-button-group > button {
    margin: 5px;
    flex-grow: 1;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}