.wp-list-table {
    svg {
        width: 52px;
        height: 52px;
    }

    &.invent {
        td {
            vertical-align: middle;

            &.column-primary {
                font-weight: bold;
                padding-right: 30px;

                .dashicons-before.text-muted {
                    opacity: .5;
                }
            }

            @media (max-width:782px) {
                text-align: left !important;
                word-break: break-word;

                &:empty {
                    padding: 0 !important;
                }
            }
        }
    }

    .td-center {
        text-align: center;
    }

    .td-right {
        text-align: right;
    }

    .header-group {
        th {
            border-left: 1px solid $table-border-color;
            border-right: 1px solid $table-border-color;
            //color: #fff;
            font-weight: bold;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .header-group-child {
        td {
            font-size: 12px;
            font-weight: bold;
            background: mix(#fff, $submit-button-color, 92%);

            &.header-group-end {
                border-right: 1px solid mix(#fff, $submit-button-color, 75%);

                + td {
                    border-left: 1px solid mix(#fff, $submit-button-color, 75%);
                }
            }
        }
    }

    .header-group-end {
        border-right: 1px solid $table-border-color;

        + td {
            border-left: 1px solid $table-border-color;
        }
    }

    .text-nowrap {
        white-space: nowrap;
    }

    .td-min-width--address {
        min-width: 180px;
    }

    .no-entries {
        font-size: 16px;
        font-style: italic;
        padding: 10px;
    }
}