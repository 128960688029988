.inventory-summary-item {
    h4 {
        border-bottom: 1px solid $input-border-color;
        padding-bottom: 10px;
        margin-top: 25px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 14px;
    }

    .inventory-summary-items {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:before {
                content: "\2022";
            }

            > * {
                display: inline-block;
                padding: 2px 5px;
            }
        }
    }

    &:first-child {
        h4 {
            margin-top: 5px;
        }
    }

    &-filler {
        flex-grow: 1;
        border-bottom: 1px dotted $input-border-color;
    }

    &-title {
        font-weight: bold;
    }

    &-qty,
    &-volume,
    &-value {
        white-space: nowrap;
    }

    &-qty {
        color: $submit-button-color;
    }

    .inventory-summary-item-divided + .inventory-summary-item-divided {
        padding-left: 0;

        &:before {
            content: "|";
            display: inline-block;
            padding-right: 5px;
            opacity: .4;
        }
    }

    .inventory-summary-item-comments {
        position: relative;

        > .comment {
            $local-comments-width: 200px;
            display: none;
            position: absolute;
            bottom: 10px;
            width: $local-comments-width;
            left: 50%;
            z-index: 10;
            margin-left: $local-comments-width / -2;
            padding: 5px 10px;
            background: $input-background-color;
            border: 1px solid $input-border-color;
            box-shadow: 0 2px 5px rgba(#000, .3);
            border-radius: 4px;
        }

        &:hover {
            > .comment {
                display: block;
            }
        }
    }
}