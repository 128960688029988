.text-white {
    color: $white;
}

.text-orange {
    color: $orange;
}

.visible-xxs {
    @media (min-width: 375px) {
        display: none !important;
    }
}

.hidden-xxs {
    @media (max-width: 374px) {
        display: none !important;
    }
}

.text-breakword {
    @include wrap-links();
}

.link-box {
    display: inline-block;
    margin: 5px 0 10px;
    padding: 10px 15px;
    border-radius: 4px;
    background: rgba($white, .075);
}

//
//  Flexbox
// ---------------------------------------
.d-flex {
    display: flex!important;
}

.flex-column {
    flex-direction: column!important;
}

.justify-content-between {
    justify-content: space-between!important;
}

.align-items-center {
    align-items: center!important;
}

.flex-fill {
    //flex: 1 1 auto!important;
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
    flex-basis: auto !important;
}

.flex-grow-1 {
    flex-grow: 1!important;
}

.flex-nowrap {
    flex-wrap: nowrap!important;
}

.flex-wrap {
    flex-wrap: wrap!important;
}