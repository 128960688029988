$local-checkbox-size: 14px;

.checkbox {
    &-box {
        @extend %vertical-inline-block;
        @include square($local-checkbox-size);
        border-radius: $border-radius-base;
        border: 1px solid $blue;
        padding: 2px;

        > svg {
            @include square(100%);
            display: block;
            fill: $white;
        }
    }

    &-label {
        @extend %vertiaclly-aligned-label;
        font-weight: bold;
    }

    &.checkbox--checked {
        .checkbox-box {
            background: $blue;
        }
    }

    //&.checkbox--noLabel {
    //    @include square($local-checkbox-size);
    //
    //    .checkbox-box {
    //        vertical-align: top;
    //    }
    //}
}