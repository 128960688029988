.spinning-loader {
    $local-loader-size: 26px;
    $local-preloader-border-width: 5px;
    $local-circle-border: #{$local-preloader-border-width} solid rgba(0, 0, 0, .15);
    $local-loader-main-color: #0085ba;
    $local-loader-support-color: #ccc;

    width: $local-loader-size;
    height: $local-loader-size;

    &:before {
        content: "";
        display: block;
        height: $local-loader-size;
        width: $local-loader-size;
        animation: rotation .6s infinite linear;
        border-left: $local-circle-border;
        border-right: $local-circle-border;
        border-bottom: $local-circle-border;
        border-top: $local-preloader-border-width solid rgba($local-loader-main-color, .7);
        border-radius: 50%;
    }
}