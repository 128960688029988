$local-remove-btn-size: 30px;
$local-remove-btn-padding: 5px;
$local-remove-btn-zIndex: 2;

.gridList {
    @extend %list;
    position: relative;

    &-item {
        @extend %list-item;
        flex-basis: 50%;

        @media (min-width: 640px){
            flex-basis: 33.3333333%;
        }

        @include media-query(md) {
            flex-basis: 25%;
        }

        @include media-query(lg) {
            flex-basis: 20%;
        }

        &-inner {
            @extend %list-item-inner;
            flex-direction: column;
        }

        &-remove {
            @extend %styless-button;
            @include square($local-remove-btn-size);
            position: absolute;
            right: round($local-remove-btn-size / -2);
            top: round($local-remove-btn-size / -2);
            z-index: $local-remove-btn-zIndex;
            padding: $local-remove-btn-padding;

            &-background {
                @include square(100%);
                display: block;
                background: $list-item-background;
                border-radius: 50%;
                -webkit-clip-path: polygon(100% 0, 100% 100%, 50% 100%, 50% 50%, 0 50%, 0 0);
                clip-path: polygon(100% 0, 100% 100%, 50% 100%, 50% 50%, 0 50%, 0 0);
                transition: background-color $transition-time, clip-path $transition-time;
            }

            &-symbol {
                @include all-center();
                position: absolute;
                z-index: $local-remove-btn-zIndex;
                display: block;
                color: $red;
                line-height: $local-remove-btn-size - ($local-remove-btn-padding * 2);
                font-size: 20px;
                transition: color $transition-time;
                font-family: sans-serif; // to avoid new font riding high
                margin-top: 0;
            }

            &:hover {
                .gridList-item-remove-background {
                    background: $red;
                    -webkit-clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0);
                    clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0);
                }

                .gridList-item-remove-symbol {
                    color: $white;
                }
            }
        }

        &-title {
            @extend %list-item-title;
        }

        &-icon {
            @extend %list-item-icon;
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $grid-item-padding 0;

            svg {
                @include square(62px, 'max-');
                display: block;
                width: 100%;
                pointer-events: none;
            }
        }

        &-actions {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            min-height: 27px;
        }

        .checkbox {
            display: inline-block;
            font-size: 11px;
            font-style: italic;
            margin: 0;
            white-space: nowrap;
            text-align: left;
            padding: 5px 7px;

            @media (min-width: $screen-sm-min) {
                font-size: 12px;
            }

            .checkbox-label {
                margin-left: 5px;
                padding-top: 5px; // to offset the new font riding high
            }
        }
    }
}