$local-modal-x-padding: 30px;
$local-modal-y-padding: 15px;

.inventApp-modal {
    position: relative;

    .modal-content {
        background-color: rgba($black, .875);
        box-shadow: 0 2px 5px rgba($black, .5);
        border: 0;
        color: $white;
    }

    .modal-header {
        position: relative;
        padding: $local-modal-y-padding $local-modal-x-padding round($local-modal-y-padding / 2);
        border: 0;
    }

    .modal-title {
        font-size: 24px;
        text-align: center;
    }

    .close {
        position: absolute;
        top: $local-modal-y-padding + 3px;
        right: $local-modal-x-padding;
        float: none;
        color: $orange;
        font-size: 30px;
        z-index: 5;
        opacity: 1;
        text-shadow: none;
    }

    .modal-body {
        padding: round($local-modal-y-padding / 2) $local-modal-x-padding;
    }

    .modal-footer {
        padding:  round($local-modal-y-padding / 2) $local-modal-x-padding round($local-modal-y-padding * 2);
        text-align: center;
        border: 0;
    }

    @import "custom-item-modal";
}

.inventApp-modalBackdrop {
    background-color: rgba($white, .8);
}