// NOTE: Some responsive styling is located in _navbar-white.scss
%_orange-nav-bg {
    background: $orange;
}

%_orange-nav {
    > li {
        > a {
            color: $black !important;
            text-transform: uppercase;
            background: transparent !important;
            font-weight: $font-weight-bold;

            &:hover,
            &:focus,
            &:active {
                background-color: mix($white, $orange, 10%) !important;
            }

            &:focus,
            &:active {
                color: $white !important;
            }
        }

        &.active,
        &.current-page-parent {
            > a {
                color: $white !important;
                background-color: mix($white, $orange, 10%) !important;
            }
        }
    }

    .dropdown-menu {
        margin: 0;
        border: 0;
        background: rgba($gray-darker, .8) !important;
        width: 100%;

        > li {
            > a {
                color: $white !important;
                background: transparent !important;

                &:hover,
                &:focus {
                    background: $dropdown-link-active-bg !important;
                }
            }

            &.active {
                > a {
                    background: rgba($white, .2) !important;
                }
            }
        }
    }
}


.nav-orange {
    @extend %_orange-nav-bg;

    .nav {
        @extend %_orange-nav;

        > li {
            > a {
                $v-padding: round(($navbar-height - floor(($font-size-h4 * $line-height-base))) / 2) - 1;
                $font-adjustment: 1px; // Adjustment for new font riding high

                font-size: $font-size-h4;
                padding: ($v-padding + $font-adjustment) 0 ($v-padding - $font-adjustment);
              
                @media (min-width: $grid-float-breakpoint) and (max-width: $screen-md-max) {
                   font-size: 15px;
                }
            }

            &.dropdown {
                > a:before {
                    @extend .fa;
                    @extend .fa-angle-down;

                    font-size: 1.4em;
                    margin-right: 16px;
                    color: $white;
                    position: relative;
                    top: 2px;
                }

                .caret {
                    display: none;
                }
            }
        }

        .dropdown-menu {
            border-radius: 0 0 $border-radius-small $border-radius-small;
        }
    }

    @media (min-width: $grid-float-breakpoint) {
        ul.nav li.dropdown:hover > ul.dropdown-menu { display: block; }
    }
}

.navbar-orange-mobile {
    @extend %_orange-nav-bg;
    @extend %_orange-nav;

    li {
        > a:not(.mobile-dropdown-toggle-btn) {
            border-bottom: 1px solid rgba($black, .2) !important;
        }

        &:last-child {
            > a {
                border-bottom: 0;
            }
        }

        &.active,
        &.current-page-parent {
            > .mobile-dropdown-toggle-btn {
                background: rgba($black, .05) !important;
            }
        }
    }

    .mobile-dropdown-toggle-btn {
        background: rgba($black, .05) !important;

        &:before {
            color: $white;
        }
    }
}