$local-button-size: (
    sm: (buttonSize: 20px, fontSize: 20px),
    md: (buttonSize: 30px, fontSize: 23px),
    lg: (buttonSize: 62px, fontSize: 37px)
);

$local-symbol: (
    plus: '+',
    minus: '-'
);


.addButton {
    @extend %styless-button;
    font-weight: bold;
    color: $white;

    &:hover {
        .addButton-plus {
            background: darken($blue, 7%);
        }

        .addButton-label {
            text-decoration: underline;
        }
    }

    &-plus {
        @extend %vertical-inline-block;
        background: $blue;
        border-radius: 50%;
        border: none;
        text-align: center;
        line-height: 1;
        transition: background-color $transition-time;
    }

    &-label {
        @extend %vertiaclly-aligned-label;
    }

    @each $size, $value in $local-button-size {
        &--#{$size} {
            .addButton-plus {
                @include square(map-get($value, buttonSize));
                position: relative;
                font-size: map-get($value, fontSize);

                &:before {
                    position: absolute;
                    display: block;
                    transform:  translate(-46%, -62%);
                    left: 50%;
                    top: 50%;
                }
            }
        }
    }

    @each $style, $value in $local-symbol {
        &--#{$style} {
            .addButton-plus {
                &:before {
                    content: $value;
                    font-family: sans-serif;

                    @if $style == plus {
                        line-height: 0;
                    }
                }
            }
        }
    }
}