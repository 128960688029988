.site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-header-banner {
    &.with-form--inventory {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        + .site-content {
            flex: unset;
        }
    }
}

.site-content {
    flex-grow: 1;
}

.margin50 {
    margin: 50px;
}

.blogs-list {
    margin: 50px 0;

    @media(min-width: $screen-sm) {
        margin: 50px;
    }
}

.quote-form {
    background: rgba(5, 7, 8, .6);
}