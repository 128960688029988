.admin-tabs {
    @extend %tabs;
}

.admin-tabs-content {
    @extend %tabs-content;

    > * {
        display: none;

        &.active {
            display: block;
        }
    }
}