// =============================================================================== //
//  Transitions -
//  For different screen animation types
// =============================================================================== //
$local-screen-transition-length: 300ms;
$local-prefix: screen-;

//
//  Screen transitions
// ---------------------------------------
.#{$local-prefix}slide-forward {
    .screen-in-enter {
        transform: translate3d(100%, 0, 0);
    }
    .screen-in-enter.screen-in-enter-active {
        transform: translate3d(0, 0, 0);
        transition: transform $local-screen-transition-length ease-in-out;
    }
    .screen-in-exit {
        transform: translate3d(0, 0, 0);
    }
    .screen-in-exit.screen-in-exit-active {
        transform: translate3d(-100%, 0, 0);
        transition: transform $local-screen-transition-length ease-in-out;
    }
}

.#{$local-prefix}slide-back {
    .screen-in-enter {
        transform: translate3d(-100%, 0, 0);
    }
    .screen-in-enter.screen-in-enter-active {
        transform: translate3d(0, 0, 0);
        transition: transform $local-screen-transition-length ease-in-out;
    }
    .screen-in-exit {
        transform: translate3d(0, 0, 0);
    }
    .screen-in-exit.screen-in-exit-active {
        transform: translate3d(100%, 0, 0);
        transition: transform $local-screen-transition-length ease-in-out;
    }
}

.#{$local-prefix}slide-up {
    .screen-in-enter {
        transform: translate3d(0, 100%, 0);
    }
    .screen-in-enter.screen-in-enter-active {
        transform: translate3d(0, 0, 0);
        transition: transform $local-screen-transition-length ease-in-out;
    }
    .screen-in-exit {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    .screen-in-exit.screen-in-exit-active {
        transform: translate3d(0, 0, 0);
        opacity: 0;
        transition: opacity $local-screen-transition-length ease-in-out;
    }
}

.#{$local-prefix}slide-down {
    .screen-in-enter {
        transform: translate3d(0, 0, 0);
        opacity: 0;
    }
    .screen-in-enter.screen-in-enter-active {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: opacity $local-screen-transition-length ease-in-out;
    }
    .screen-in-exit {
        transform: translate3d(0, 0, 0);
    }
    .screen-in-exit.screen-in-exit-active {
        transform: translate3d(0, 100%, 0);
        transition: transform $local-screen-transition-length ease-in-out;
    }
}

%post-absolute {
    position: absolute;
    left: 0;
    right: 0;
}

.#{$local-prefix}fade {
    .screen-in-enter {
        @extend %post-absolute;
        opacity: 0;
    }
    .screen-in-enter.screen-in-enter-active {
        @extend %post-absolute;
        opacity: 1;
        transition: opacity $local-screen-transition-length ease-in-out;
    }
    .screen-in-exit {
        @extend %post-absolute;
        opacity: 1;
    }
    .screen-in-exit.screen-in-exit-active {
        @extend %post-absolute;
        opacity: 0;
        transition: opacity $local-screen-transition-length ease-in-out;
    }
}

//
//  UI transitions
// ---------------------------------------
.fade-enter {
    opacity: 0;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity $local-screen-transition-length ease-in-out;
}
.fade-exit {
    opacity: 1;

    .transition-fade & {
        display: none;
    }
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity $local-screen-transition-length ease-in-out;

    .transition-fade & {
        display: none;
    }
}

////
////  Animation
//// ---------------------------------------
//.pulse-animation {
//    animation: pulse 1s infinite;
//}
//
//@keyframes pulse {
//    $_pulse-amount: 30%;
//    0% { transform: translateY(-$_pulse-amount); }
//    50% { transform: translateY(0); }
//    100% { transform: translateY(-$_pulse-amount); }
//}
//
//@keyframes progress-rotate {
//    from { transform: rotate(0deg); }
//}