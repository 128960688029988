.appAlert {
    position: fixed;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 100;
    transition: top $transition-length;

    @each $size, $height in $header-height {
        @include media-query($size) {
            top: $height;
        }
    }

    @each $size, $height in $scrolled-header-height {
        @include media-query($size) {
            .site-header.sticky + .with-form--inventory & {
                top: $height;
            }
        }
    }

    &-alert {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row-reverse;
        align-items: center;
        color: $white;
        font-weight: bold;
        padding: round($grid-gutter-width * .25);

        &-message {
            $message-padding: 10px;
            $font-offset: 4px; // To offset the new font riding higher

            flex-grow: 1;
            padding: ($message-padding + $font-offset) $message-padding ($message-padding - $font-offset);
        }

        .close {
            @extend %styless-button;
            color: $white;
            padding: 10px 15px;
            font-size: 26px;
            font-weight: bold;
            line-height: 1;
            opacity: 1;
            text-shadow: none;
            font-family: sans-serif; // offset weird font riding higher thing for an "icon"
        }

        &--show {
            animation: slide-down $transition-length;
            animation-fill-mode: both;
        }

        &--hide {
            animation: slide-up $transition-length;
            animation-fill-mode: both;
        }

        &--danger {
            background: $red;
        }

        &--success {
            background: $green;
        }
    }
}