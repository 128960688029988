$admin-background-color:    #f1f1f1;
$input-background-color:    #fff;
$input-border-color:        #ddd;
$submit-button-color:       #0085ba;
$error-color:               #dc3232;
$success-color:             #46b450;
$warning-color:             #ffb900;
$table-border-color:        #e1e1e1;
$muted-text-color:          #999;

$tabs-background:           $input-background-color;
$tabs-border-color:         $input-border-color;
$tabs-content-padding:      20px;